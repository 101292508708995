<template>
  <div class='list'>
    <div class="globle_border">
      <div class="search">
        <el-input v-model="search" placeholder="搜索标题名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button class="btn" type="primary" @click="handleAdd(1)">新增</el-button>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" min-width='100'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="title" label="标题" show-overflow-tooltip></el-table-column>
          <el-table-column prop="content" label="内容" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contentUrl" label="图片" align="center">
            <template slot-scope="scope">
              <el-image style="width: 180px; height: 69px" :src="scope.row.contentUrl" fit="cover" @click="previewImage(scope.row.contentUrl)"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="linkUrl" label="链接" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="sortNum" label="排序"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" min-width="200">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" plain @click="handleEdit(scope.row, 2)">编辑</el-button>
              <el-button class="btn" type="danger" size="small" plain @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
    <el-image-viewer v-if="showViewer" :on-close="() => { showViewer = false }" :url-list="imgList"></el-image-viewer>
    <el-dialog :title="titles" :visible.sync="dialogVisible" :before-close="onCancel" width="80%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="标题" prop="title">
              <el-input v-model="ruleForm.title" placeholder="请输入标题"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示顺序" prop="sortNum">
              <el-input v-model="ruleForm.sortNum" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="图片上传" prop="upload">
              <el-upload v-model="ruleForm.fileUrl" ref="upload" :action="apiBaseUrl" name="multipartFile" :headers="apiHeaders" :limit="1" :file-list="importFiles" accept=".jpg,.png" :on-success="handleSuccess" :before-upload="beforeUpload">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="链接" prop="linkUrl">
              <el-input v-model="ruleForm.linkUrl" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="内容" prop="content">
              <el-input type="textarea" placeholder="请输入内容" v-model="ruleForm.content" maxlength="200" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data () {
    return {
      titles: '',
      dialogVisible: false,
      apiBaseUrl: this.$axios.defaults.baseURL + this.$api.uploadFile,
      apiHeaders: {
        token: this.$store.state.token,
      },
      importFiles: [],
      ruleForm: {
        title: '',
        sortNum: '',
        linkUrl: '',
        content: '',
        fileUrl: ''
      },
      rules: {
        fileUrl: [{ required: true, message: '请上传', trigger: 'change' }],
        content: [{ required: true, message: '请输入', trigger: 'submit' }],
        linkUrl: [{ required: true, message: '请输入链接', trigger: 'submit' }],
        title: [{ required: true, message: '请输入标题', trigger: 'submit' }],
        sortNum: [{ required: true, message: '请输入整数', trigger: 'submit' },
        {
          validator: function (rule, value, callback) {
            if (/^[1-9]\d*$/.test(value) == false) {
              callback(new Error("请输入整数"));
            } else {
              //校验通过
              callback();
            }
          },
          trigger: "blur"
        }
        ],
      },
      search: '',
      tableData: [
        {
          businessLicenseUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        }
      ],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      // 图片预览
      showViewer: false,
      imgList: [],
      shopId: '',
      index: ''
    }
  },
  created () {
    this.shopId = this.$store.state.userInfo.shopId;
    this.getPageList()
  },
  methods: {
    // handleRemove(file, fileList) {
    //   console.log(file, fileList);
    //   this.fileUrl = ''
    // },
    handleSuccess (res) {
      if (res.code == 100) {
        this.$message.success(res.desc);
        this.ruleForm.fileId = res.result.fileId
        this.ruleForm.fileUrl = res.result.fileUrl
      } else {
        this.$message.error(res.desc);
        this.importFiles = [];
      }
    },
    beforeUpload (file) {
      // console.log(file);
      const suffixArr = ["jpg", "png"];
      if (!suffixArr.includes(file.name.split(".").pop())) {
        this.$message.error(`只能上传"jpg""png"格式图片`);
        return false;
      }
    },
    // 列表
    getPageList () {
      this.loading = true;
      this.$axios.get(this.$api.pageList, {
        params: {
          bizId: this.shopId,
          bizType: 2,
          titleName: this.search,
          page: this.currentPage,
          pageSize: this.pageSize,

        },
      }).then((res) => {
        this.tableData = res.data.result.list;
        this.totalItemsCount = res.data.result.totalCount;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 搜索
    onSearch () {
      this.tableData = []
      this.currentPage = 1
      this.getPageList()
    },

    // 切换每页显示的条数
    handleSizeChange (e) {
      this.tableData = []
      this.pageSize = e;
      this.getPageList();
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = []
      this.currentPage = e;
      this.getPageList();
    },
    // 打开对话框
    handleAdd (e) {
      this.titles = '新增'
      this.index = e
      this.dialogVisible = true
    },
    // 关闭对话框
    onCancel () {
      this.resetForm()
      this.dialogVisible = false
    },
    // 重置表单
    resetForm () {
      this.ruleForm = {}
      this.$refs.upload.clearFiles();
      this.$refs.ruleForm.resetFields();
      this.ruleForm.fileUrl = ''
      this.importFiles = [];
      console.log(this.ruleForm);
    },
    // 提交表单
    submitForm (formName) {
      let param = {
        bizId: this.shopId,
        bizType: 2,
        contentType: 1,
        title: this.ruleForm.title,
        sortNum: this.ruleForm.sortNum,
        linkUrl: this.ruleForm.linkUrl,
        content: this.ruleForm.content,
        contentUrl: this.ruleForm.fileUrl,
        id: this.id,
      }
      if (this.index == 1) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.post(this.$api.addBanner, param).then((res) => {
              if (res.data.code == 100) {
                this.$message.success('新增成功');
                //提交成功后，清空上传图片的路径
                this.resetForm();
                this.importFiles = []
                this.dialogVisible = false;
                this.getPageList();
              }
            });
          } else {
            this.$message.error('新增失败');
            return false;
          }
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.post(this.$api.updateBanner, param).then((res) => {
              if (res.data.code == 100) {
                this.$message.success('修改成功');
                this.resetForm();
                this.dialogVisible = false;
                this.getPageList();
              }
            });
          } else {

            this.$message.error('修改失败');
            return false;
          }
        });
      }
    },
    // 编辑
    handleEdit (row, e) {
      this.dialogVisible = true
      let data = Object.assign({}, row)
      // console.log(row);
      this.titles = '编辑'
      this.index = e
      this.ruleForm = data
      this.id = data.id
      this.ruleForm.fileUrl = data.contentUrl
      this.importFiles = [{ url: data.contentUrl, name: data.contentUrl }]
    },
    // 删除
    handleDel (row) {
      this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(this.$api.delBanner, {
          bizId: this.shopId,
          bizType: 2,
          id: row.id
        }).then((res) => {
          if (res.data.code == 100) {
            this.$message.success(res.data.desc)
          }
          this.getPageList()
        })
      }).catch(() => { });
    },
    // 图片预览
    previewImage (url) {
      this.imgList[0] = url
      this.showViewer = true
    },
  },
}
</script>

<style scoped lang='scss'>
.list {
  /deep/.el-textarea__inner {
    height: 172px;
    background-color: #f2f2f2 !important;
    border: none !important;
  }

  /deep/ .el-textarea .el-input__count {
    background: transparent !important;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      margin-left: 20px;
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>